import React, { useState, useEffect, useContext } from 'react';
import JoditEditor from 'jodit-react';
import { useForm, Controller } from 'react-hook-form';
import { X } from 'lucide-react';
import MultiSelectDropdown from '../ui/MultiSelectDropdown';
import CustomDropdown from '../ui/SingleSelect';
import SingleDropdown from '../ui/singleselectvalueid';
import { Table, Button, Tag } from 'antd';
import { Tooltip } from 'antd';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { useCourse } from '../../context/coursecontext';
import { useLocation, useNavigate } from 'react-router-dom';
import { post } from '../../services/fetch';
import { Modal } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie'
const Step3 = () => {
  const location = useLocation();
  const navigate = useNavigate();
 
  useEffect(() => {
    // Check if the '_GACOOKIE' token exists
    const token = Cookies.get('_GACOOKIE_ADMIN');
    if (!token) {
      // If token does not exist, redirect to the homepage
      navigate('/');
    }
  }, [navigate]);
  const { courseId, chapterId, setCourseId, updateChapterId, setLectureId } = useCourse(); // Destructure context functions
  const [selectedAuthors, setSelectedAuthors] = useState([]);
  
  
  const searchParams = new URLSearchParams(location.search);
  const chapter_id = searchParams.get('chapter_id');
  const { control,setValue,register} = useForm();

  const [lectureData, setLectureData] = useState({
    chapter_id: chapter_id,
    lectureTitle: '',
    lectureType: '',
    // lecture_desc: '',
    duration: '',
    certificate: null,
    status: '',
    startDate: '',
    endDate: '',
    authors: [],
    videos: [],
    mcqs: [],
  });
  const [isEditing, setIsEditing] = useState(false);
  const [certificatePreview, setCertificatePreview] = useState(null);
  const [videoUrl, setVideoUrl] = useState('');
  const [isUnlimited, setIsUnlimited] = useState(false);
  const [errors, setErrors] = useState({});
  const [authors, setAuthors] = useState([]);
  const [chapterLectures, setChapterLectures] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showMCQModal, setShowMCQModal] = useState(false);
  const [parentLectureOptions, setParentLectureOptions] = useState([]);

  const [showForm, setShowForm] = useState(false); // State to control form visibility
  const [mcqData, setMcqData] = useState({
    passingMarks: '',
    unlimitedAttempts: '',
    retryAttempts: '',
    mcqDuration: '',
  });
  useEffect(() => {
    // Extract course_id and chapter_id from URL and set in context
    const searchParams = new URLSearchParams(location.search);
    const course_id_from_url = searchParams.get('course_id');
    const chapter_id_from_url = searchParams.get('chapter_id');
  
    if (course_id_from_url) {
      console.log("Setting courseId from URL:", course_id_from_url);
      setCourseId(course_id_from_url); // Update the context with courseId from URL
    }
    if (chapter_id_from_url) {
      updateChapterId(chapter_id_from_url); // Update the context with chapterId from URL
    }
  }, [location.search]); // Removed the setters from the dependencies
  
  
  useEffect(() => {
    document.title = isEditing ? 'Edit Lecture | Medtalks Academy Admin' : 'Add Lecture | Medtalks Academy Admin';
  }, [isEditing]);
   
  const handleVideoChange = (index, field, value) => {
    const updatedVideos = [...lectureData.videos];
    if (!updatedVideos[index]) {
      updatedVideos[index] = { [field]: value };
    } else {
      updatedVideos[index] = { ...updatedVideos[index], [field]: value };
    }
    setLectureData({ ...lectureData, videos: updatedVideos });
  };
  
  const handleAuthorChange = (selectedItems) => {
    // Assuming selectedItems is an array of selected option objects
    setSelectedAuthors(selectedItems);
  };
  
  useEffect(() => {
    const fetchParentLectures = async () => {
      if (chapter_id) {  // Ensure chapter_id exists
        try {
          const response = await post('get-parent-lecture', { chapter_id }, true, true);
          if (response.code === 200 && Array.isArray(response.data)) {
            const options = response.data.map((lecture) => ({
              label: lecture.lecture_title,
              value: lecture.id,  // Assuming id is the value you need to store
            }));
            setParentLectureOptions([{ label: 'Select Parent Lecture', value: '' }, ...options]);
          } else {
            console.error('Failed to fetch parent lectures or invalid data format');
          }
        } catch (error) {
          console.error('Error fetching parent lectures:', error);
        }
      }
    };
  
    fetchParentLectures();
  }, [chapter_id]);  // Add chapter_id as a dependency to refetch when it changes
  
  const handleParentLectureChange = (selectedValue) => {
    console.log('Selected Parent Lecture:', selectedValue); // Debugging log to verify selected value
    setLectureData((prevData) => ({
      ...prevData,
      parentLectureCompleted: selectedValue || '', // Directly assign the selected value
    }));
  };
  
  const validateForm = () => {
    const newErrors = {};
    
    // Validate Lecture Title
    if (!lectureData.lectureTitle) {
      newErrors.lectureTitle = 'Lecture Title is required';
    }

    // Validate Duration (HH:MM:SS format)
    if (!lectureData.duration || !/^([0-9]{2}):([0-9]{2}):([0-9]{2})$/.test(lectureData.duration)) {
      newErrors.duration = 'Duration must be in HH:MM:SS format';
    }

    // Validate Start and End Date
    if (!lectureData.startDate) {
      newErrors.startDate = 'Start Date is required';
    }
    if (!lectureData.endDate) {
      newErrors.endDate = 'End Date is required';
    }

    // if (!lectureData.lecture_desc) {
    //   newErrors.lecture_desc = 'Lecture Description is required';
    // }

    if (!lectureData.lectureType) {
      newErrors.lectureType = 'Lecture Type is required';
    }

    if (!lectureData.status) {
      newErrors.status = 'Lecture Status is required';
    }




    // Validate MCQ Passing Marks (if applicable)
    if (lectureData.lectureType === 'mcq' && !lectureData.mcqs[0]?.passingMarks) {
      newErrors.passingMarks = 'Passing Marks are required';
    }

    // Validate Retry Attempts and Minimum Question Attempts (if Unlimited Attempts is false)
    if (lectureData.lectureType === 'mcq' && !isUnlimited) {
      if (!lectureData.mcqs[0]?.retryAttempts) {
        newErrors.retryAttempts = 'Retry Attempts are required';
      }
      if (!lectureData.mcqs[0]?.minimumQuestionsAttempts) {
        newErrors.minimumQuestionsAttempts = 'Minimum Question Attempts are required';
      }
      if (!lectureData.mcqs[0]?.retryAttempts) {
        newErrors.retryAttempts = 'Minimum Question Attempts are required';
      }

    }

    // Validate Video URL (if applicable)
    if (lectureData.lectureType === 'video') {
      const videoErrors = [];
      lectureData.videos.forEach((video, index) => {
        if (!video.video_url) {
          videoErrors[index] = 'Video URL is required';
        }
      });
      if (videoErrors.length) {
        newErrors.videos = videoErrors;
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };
  
  const validateDuration = (duration) => {
    // Check if duration is empty
    if (!duration) {
      setErrors(prev => ({...prev, duration: 'Duration is required'}));
      return false;
    }

    // Check format HH:MM:SS
    const durationRegex = /^([0-1][0-9]|2[0-4]):([0-5][0-9]|60):([0-5][0-9]|60)$/
    if (!durationRegex.test(duration)) {
      setErrors(prev => ({
        ...prev, 
        duration: 'Invalid duration format. Use HH:MM:SS (e.g., 01:30:00)'
      }));
      return false;
    }

    // If we get here, duration is valid
    setErrors(prev => {
      const newErrors = {...prev};
      delete newErrors.duration;
      return newErrors;
    });
    return true;
  };

  
  useEffect(() => {
    if (chapter_id) {
      fetchChapterLectures(chapter_id);
    }
  }, [chapter_id]);

  const fetchChapterLectures = async (chapterId) => {
    try {
      const response = await post('get-lecture', { chapter_id: chapterId }, true, true);
      if (response.code === 200 && Array.isArray(response.data)) {
        setChapterLectures(response.data);
      } else {
        console.error('Failed to fetch chapter lectures or invalid data format');
        setChapterLectures([]); // Set to empty array if data is invalid
      }
    } catch (error) {
      console.error('Error fetching chapter lectures:', error);
      setChapterLectures([]); // Set to empty array on error
    }
  };
  const fetchAuthors = async () => {
    try {
      const response = await post('get-instructors', true, true);
      if (response.code === 200) {
        const authorData = response.data.map((author) => ({
          value: author.id,
          label: author.name,
        }));
        setAuthors(authorData);
      } else {
        console.error('Failed to fetch authors');
      }
    } catch (error) {
      console.error('Error fetching authors:', error);
    }
  };

  useEffect(() => {
    fetchAuthors();
    console.log("Authors fetched:", authors); // Check if authors are populated
}, []);


  const handleAddLecture = () => {
    setIsEditing(false); // Clear form data for new lecture
    setLectureData({
      chapter_id: chapter_id,
      lectureTitle: '',
      lectureType: '',
      // lecture_desc: '',
      duration: '',
      certificate: null,
      status: '',
      startDate: '',
      endDate: '',
      authors: [],
      videos: [],
      mcqs: [],
    });
    setShowForm(true); // Show form for adding lecture
  };


  const handleEditLecture = async (lectureId) => {
    setIsEditing(true);
    setLoading(true);
    setError(null);
  
    try {
      const response = await post('get-lecture', { chapter_id, lecture_id: lectureId }, true, true);
      if (response.code === 200 && response.data.length > 0) {
        const lectureData = response.data[0];
        setLectureData({
          id: lectureData.id,
          lectureTitle: lectureData.lecture_title,
          lectureType: lectureData.type,
          // lecture_desc: lectureData.lecture_desc,
          duration: lectureData.duration || '',
          certificate: null, // This field is not present in the response
          status: lectureData.status,
          startDate: lectureData.start_date || '',
          endDate: lectureData.end_date || '',
          authors: [], // This field is not present in the response
          parentLectureCompleted: lectureData.parent_lecture_id || '', // Prefill Parent Lecture Completed field
          videos: lectureData.video_url ? [{ video_url: lectureData.video_url }] : [],
          mcqs: lectureData.type === 'mcq' ? [{
            passingMarks: lectureData.marks,
            unlimitedAttempts: lectureData.is_unlimited_reattempts === 1,
            retryAttempts: lectureData.retry_attempts,
            mcqDuration: lectureData.duration || '',
            minimumQuestionsAttempts: lectureData.minimum_questions_attempts || '', // Prefill Minimum Question Attempts
          }] : [],
        });
        setShowForm(true);
        setLoading(false);
      } else {
        console.error('Failed to fetch lecture details');
        setError('Failed to fetch lecture details or invalid data format');
        setLoading(false);
        setIsEditing(false);
      }
    } catch (error) {
      console.error('Error fetching lecture:', error);
      setError('An error occurred while fetching lecture details.');
      setLoading(false);
      setIsEditing(false);
    }
  };
  
  const handleTypeChange = (e) => {
    const { name, value } = e.target;
  
    if (value === 'video' && lectureData.videos.length === 0) {
      // Add an empty video object if switching to "Video" and no video object exists
      setLectureData({ ...lectureData, lectureType: value, videos: [{ video_url: '' }] });
    } else if (value !== 'video') {
      // Clear the videos array if switching away from "Video"
      setLectureData({ ...lectureData, lectureType: value, videos: [] });
    } else {
      setLectureData({ ...lectureData, lectureType: value });
    }
  };
  

  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === 'file') {
      setLectureData({ ...lectureData, [name]: files[0] });

      if (files[0]) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setCertificatePreview(reader.result);
        };
        reader.readAsDataURL(files[0]);
      } else {
        setCertificatePreview(null);
      }
    } else {
      if (name === 'duration' || name === 'mcqDuration') {
        const numericValue = value.replace(/\D/g, '');
        let formattedValue = numericValue;

        if (numericValue.length > 2) {
          formattedValue = `${numericValue.slice(0, 2)}:${numericValue.slice(2)}`;
        }
        if (numericValue.length > 4) {
          formattedValue = `${formattedValue.slice(0, 5)}:${numericValue.slice(4)}`;
        }
        if (formattedValue.length > 8) {
          formattedValue = formattedValue.slice(0, 8);
        }

        if (name === 'duration') {
          setLectureData({ ...lectureData, [name]: formattedValue });
        } else {
          setMcqData({ ...mcqData, mcqDuration: formattedValue });
        }
      } else {
        setLectureData(prevData => ({
          ...prevData,
          [name]: value
        }));
      }
    }
  };

 

  const handleCertificateUpload = (e) => {
    const file = e.target.files[0];
    setLectureData({ ...lectureData, certificate: file });

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCertificatePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setCertificatePreview(null);
    }
  };

  const handleAddVideo = () => {
    if (videoUrl) {
      setLectureData((prevData) => ({
        ...prevData,
        videos: [...prevData.videos, { video_url: videoUrl }],
      }));
      setVideoUrl('');
    }
  };

  const handleAddMCQ = () => {
    setLectureData({
      ...lectureData,
      mcqs: [...lectureData.mcqs, mcqData],
    });
    setMcqData({
      passingMarks: '',
      unlimitedAttempts: false,
      retryAttempts: '',
      mcqDuration: '',
    });
    setShowMCQModal(false);
  };

  const handleMcqChange = (field, value) => {
    const updatedMcqs = [...lectureData.mcqs];
    if (updatedMcqs.length === 0) {
      updatedMcqs.push({ [field]: value });
    } else {
      updatedMcqs[0] = { ...updatedMcqs[0], [field]: value };
    }
  
    if (field === 'unlimitedAttempts') {
      setIsUnlimited(value);
      updatedMcqs[0].unlimitedAttempts = value;
      if (!value) {
        updatedMcqs[0].retryAttempts = ''; // Clear retry attempts when unlimitedAttempts is true
      }
    }
  
    setLectureData({ ...lectureData, mcqs: updatedMcqs });
  };
  
  const handleViewMCQs = (lectureId) => {
    navigate(`/add-mcq/mcq?lecture_id=${lectureId}`);
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setErrors({});

      const payload = {

        id: lectureData.id, // Include id for updating an existing lecture
        lecture_title: lectureData.lectureTitle,
        status: lectureData.status,
        start_date: lectureData.startDate,
        end_date: lectureData.endDate,
        duration: lectureData.duration,
        course_id: courseId,
        instructor: selectedAuthors, // Add instructor IDs to the payload
        chapter_id: chapterId,
        type: lectureData.lectureType,
        parent_lecture_id: lectureData.parentLectureCompleted ? lectureData.parentLectureCompleted.toString() : '', // Ensure value is not undefined
      };
  
      // Add video or MCQ specific data to the payload
      if (lectureData.lectureType === 'video') {
        payload.video_url = lectureData.videos[0]?.video_url || '';
      } else if (lectureData.lectureType === 'mcq') {
        payload.marks = lectureData.mcqs[0]?.passingMarks || 0;
        payload.retry_attempts = lectureData.mcqs[0]?.retryAttempts || 0;
        payload.minimum_questions_attempts = lectureData.mcqs[0]?.minimumQuestionsAttempts?.toString() || '0'; 
        payload.is_unlimited_reattempts = lectureData.mcqs[0]?.unlimitedAttempts ? 1 : 0;
      }
  
      if (lectureData.parentLectureCompleted) {
        payload.parent_lecture_id = lectureData.parentLectureCompleted.toString();
      }
  
      try {
        const response = await post(
          isEditing ? 'update-lecture' : 'add-lecture', // Use update-lecture API if editing
          payload,
          true,
          true
        );
        if (response.code === 200) {
          console.log(isEditing ? 'Lecture updated successfully' : 'Lecture added successfully', response.data);
          setLectureId(response.data.lecture_id); // Update the lecture_id in the context
          await fetchChapterLectures(chapter_id);
          // Reset the form state and redirect back to the chapter listing page
          setShowForm(false);
          navigate(`?chapter_id=${chapter_id}&step=3`);
        } else {
          console.error('Unexpected status:', response.status);
          setErrors({ general: 'Unexpected response status' });
        }
      } catch (error) {
        if (error.response?.status === 401) {
          setErrors({ general: 'Unauthorized. Please login again.' });
        } else {
          setErrors(error.response?.data?.errors || { general: 'An error occurred' });
        }
      }
    }
  };
  
  
  // Update the joditConfig object with these settings
  const joditConfig = {
    height: 300,
    defaultActionOnPaste: 'insert_as_html',
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    beautifyHTML: false,
    useAceEditor: false,
    removeButtons: ['about'],
    placeholder:'',
    showXPathInStatusbar: false,
    toolbarAdaptive: false,
    cleanHTML: false,
    paste: {
        keepClass: true,
        keepStyle: true,
        cleanHTML: false,
        cleanOnPaste: false,
        removeTags: false,
        removeAttr: false,
        removeStyle: false
    },
    events: {
        beforePaste: function (pasteEvent) {
            console.log('Before paste event:', pasteEvent);
            return true;
        },
        afterPaste: function (pasteEvent) {
            console.log('After paste event:', pasteEvent);
        }
    },
    controls: {
        source: {
            exec: function (editor) {
                editor.toggleMode();
            }
        }
    },
    buttons: [
        'source', '|',
        'bold', 'italic', 'underline', '|',
        'ul', 'ol', '|',
        'font', 'fontsize', '|',
        'paste', 'copyformat'
    ]
};

  
  const confirmDeleteLecture = (lectureId) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this lecture?',
      content: 'This action cannot be undone.',
      okText: 'Yes, Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: () => handleDeleteLecture(lectureId),
    });
  };
  
  const handleDeleteLecture = async (lectureId) => {
    setChapterLectures((prevLectures) => prevLectures.filter(lecture => lecture.id !== lectureId));
  
    try {
      const payload = {
        id: lectureId,
        type: 'lecture'
      };
  
      const response = await post('delete-content', payload, true, true);
      if (response.status === 200) {
        console.log('Lecture deleted successfully');
        // Optionally, you can refetch the lectures to make sure the state is accurate
        // fetchChapterLectures(chapter_id);
      } else {
        console.error('Failed to delete lecture:', response.status);
        setErrors({ general: 'Failed to delete the lecture. Please try again.' });
        // Rollback the optimistic update if the deletion fails
      }
    } catch (error) {
      console.error('Error deleting lecture:', error);
      setErrors({ general: 'An error occurred while deleting the lecture.' });
      // Rollback the optimistic update if an error occurs
      setChapterLectures((prevLectures) => [...prevLectures, setChapterLectures]); // Add the deleted lecture back to the list
    }
  };
  
   
  const columns = [
    {
      title: 'Lecture Title',
      dataIndex: 'lecture_title',
      key: 'lecture_title',
    },
    // {
    //   title: 'Description',
    //   dataIndex: 'lecture_desc',
    //   key: 'lecture_desc',
    //   render: (text) => (
    //     <span
    //       dangerouslySetInnerHTML={{
    //         __html: text.length > 100 ? `${text.substring(0, 100)}...` : text,
    //       }}
    //     />
    //   ),
    // },
    
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (type) => (
        <Tag bordered={false} style={{ backgroundColor: '#FFF3E5', color: '#A55107',bordered:'false' }}>{type}</Tag>
      ),
    },    
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag bordered={false} style={{ backgroundColor: '#F6FFED', color: '#2A8006',bordered:'false' }}>{status}</Tag>

      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <div className="space-x-2">
         
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => handleEditLecture(record.id)}
            style={{ backgroundColor: '#247F4D', borderColor: '#247F4D' }}
          >
          </Button>
          {record.type === 'mcq' && (
            <Button
              type="primary"
              icon={<EyeOutlined />}
              onClick={() => handleViewMCQs(record.id)}
            >
            </Button>
          )}
                  <Button
          type="danger"
          icon={<DeleteOutlined />}
          onClick={() => confirmDeleteLecture(record.id)}
          style={{ marginLeft: '10px' }}
        />

        </div>
      ),
    },
  ];

  return (
    <div className="min-h-screen p-4 sm:p-6">
      <div className="max-w-7xl mx-auto bg-white p-4 sm:p-8 rounded-lg sm:shadow-md ">
        <h1 className="text-xl font-bold mb-6 text-black">
          Lecture Details
        </h1>

        {!showForm ? (
          <>
            <div className="mb-8">
              {Array.isArray(chapterLectures) && chapterLectures.length > 0 ? (
                    <Table
                    dataSource={chapterLectures}
                    columns={columns}
                    rowKey="id"
                    pagination={false}
                  />
              ) : (
                <p>No lectures found for this chapter.</p>
              )}
            </div>

    <div className="mt-8">
      <button
        className="bg-[#027253] text-white py-2 px-4 rounded-md hover:bg-[#025a43] transition duration-300"
        onClick={handleAddLecture}
      >
        Add Lecture
      </button>
    </div>
  </>
) : (
  <form onSubmit={handleSubmit}>
  {/* Lecture Title */}
  <div className="mb-4">
    <label htmlFor="lectureTitle" className="block text-sm font-medium text-gray-700">
      Lecture Title
    </label>
    <input
      type="text"
      name="lectureTitle"
      value={lectureData.lectureTitle}
      onChange={handleInputChange}
      className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-[#027253] focus:border-[#027253]"
    />
    {errors.lectureTitle && (
    <p className="mt-1 text-sm text-red-600">{errors.lectureTitle}</p> // Display error message
  )}
    
  </div>

  {/* Lecture Type Dropdown */}
  <div className='grid sm:grid-cols-3 grid-cols-1 gap-3 '>
  <div className="mb-3">
    <label htmlFor="lectureType" className="block text-sm font-medium text-gray-700">
      Lecture Type
    </label>
    <CustomDropdown
      name="lectureType"
      value={lectureData.lectureType}
      options={[
        { label: 'Select Lecture Type', value: '' },
        { label: 'Video', value: 'video' },
        { label: 'MCQ', value: 'mcq' },
      ]}
      onChange={handleTypeChange}
      className="bg-white"
    />
{errors.lectureType && (
    <p className="mt-1 text-sm text-red-600">{errors.lectureType}</p> // Display error message
  )}
  </div>
  {/* Duration */}
<div>
  <label htmlFor="duration" className="block text-sm font-medium text-gray-700">
    Duration (HH:MM:SS)
  </label>
  <input
    type="text"
    name="duration"
    value={lectureData.duration}
    onChange={(e) => {
      handleInputChange(e); // continue handling the input change
      validateDuration(e.target.value); // validate on change, you could also use onBlur to validate when the input loses focus
    }}
    placeholder="00:00:00"
    className={`mt-1 p-1.5 block w-full border ${
      errors.duration ? 'border-red-500' : 'border-gray-300'
    } rounded-md shadow-sm focus:ring-[#027253] focus:border-[#027253]`}
  />
  {errors.duration && (
    <p className="mt-1 text-sm text-red-600">{errors.duration}</p> // Display error message
  )}

</div>
<div>
  {/* Status Dropdown */}
  <CustomDropdown
    label="Status"
    name="status"
    value={lectureData.status}
    options={[
      { label: 'Select Status', value: '' },
      { label: 'Live', value: 'live' },
      { label: 'Schedule', value: 'schedule' },
      { label: 'Draft', value: 'draft' },
      { label: 'Archive', value: 'archive' },
    ]}
    onChange={handleInputChange}
  />
             {errors.status && (
          <p className="mt-1 text-sm  text-red-600">{errors.status}</p> // Display error message
        )}
</div>
  </div>
  <div className="mb-4">
    <label htmlFor="authors" className="block text-sm font-medium text-gray-700">Authors</label>
    <SingleDropdown
      options={authors} // Options should be available from state
      value={selectedAuthors} // Control component with state
      onChange={handleAuthorChange} // Update state on change
      labelledBy="Select Authors"
    />
  </div>

  {/* JoditEditor for descriptions */}
  {/* <div className="mb-4">
    <label htmlFor="lecture_desc" className="block text-sm font-medium text-gray-700">
        Lecture Description
    </label>
    <JoditEditor
        value={lectureData.lecture_desc}
        config={joditConfig}
        onBlur={(newContent) => {
            console.log('Editor content on blur:', newContent);
            setLectureData(prev => ({ ...prev, lecture_desc: newContent }));

        }}
        
        tabIndex={1}
    />
      {errors.lecture_desc && (
    <p className="mt-1 text-sm text-red-600">{errors.lecture_desc}</p> // Display error message
  )}

</div> */}




  {/* Start and End Date */}
  <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-4">
    <div>
      <label htmlFor="startDate" className="block text-sm font-medium text-gray-700">Start Date</label>
      <input
        type="date"
        id="startDate"
        name="startDate"
        value={lectureData.startDate}
        onChange={handleInputChange}
        className="w-full p-2 border rounded-md focus:ring-[#027253] focus:border-[#027253]"
      />
        {errors.startDate && (
    <p className="mt-1 text-sm text-red-600">{errors.startDate}</p> // Display error message
  )}

    </div>
    <div>
      <label htmlFor="endDate" className="block text-sm font-medium text-gray-700">End Date</label>
      <input
        type="date"
        id="endDate"
        name="endDate"
        value={lectureData.endDate}
        onChange={handleInputChange}
        className="w-full p-2 border rounded-md focus:ring-[#027253] focus:border-[#027253]"
      />
              {errors.endDate && (
    <p className="mt-1 text-sm text-red-600">{errors.endDate}</p> // Display error message
  )}

    </div>
    {/* Parent Lecture Completed Dropdown */}
    {/* Parent Lecture Completed Dropdown */}
<div className="mb-4">
  <label htmlFor="parentLectureCompleted" className="block text-sm font-medium text-gray-700">
    Parent Lecture Completed
  </label>
  <SingleDropdown
    value={lectureData.parentLectureCompleted} // Make sure this is bound correctly
    options={parentLectureOptions} // Ensure this is an array of {label, value}
    onChange={(selectedValue) => handleParentLectureChange(selectedValue)} // Update function to set state properly
  />
</div>

  </div>

{/* Video Section */}
{lectureData.lectureType === 'video' && (
   
  <div className="mt-4">
    {lectureData.videos && lectureData.videos.map((video, index) => (
      <div key={index} className="mt-2  rounded-md">
        <label className="block text-sm font-medium text-gray-700">Video URL</label>
        <input
          type="text"
          value={video.video_url}
          onChange={(e) => handleVideoChange(index, 'video_url', e.target.value)}
          className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm"
          placeholder="Enter video URL"
        />
 {errors.videos && errors.videos[index] && (
          <p className="mt-1 text-sm text-red-600">{errors.videos[index]}</p>
        )}
      </div>
        
      
    ))}
  </div>
)}
{/* MCQ Section */}
{lectureData.lectureType === 'mcq' && (
  <div className="mt-4">
    <h2 className="text-sm font-semibold mb-4">MCQs Details</h2>
    <div className="grid sm:grid-cols-4 grid-cols-1 gap-4">
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Passing Marks</label>
        <input
          type="number"
          value={lectureData.mcqs[0]?.passingMarks || ''}
          onChange={(e) => handleMcqChange('passingMarks', e.target.value)}
          className="mt-1 p-1.5 block w-full border border-gray-300 rounded-md shadow-sm"
        />
         {errors.passingMarks && (
          <p className="mt-1 text-sm text-red-600">{errors.passingMarks}</p> // Display error message
        )}
      </div>

      <div className="mb-4">
        <CustomDropdown
          label="Unlimited Attempts"
          name="unlimitedAttempts"
          value={(lectureData.mcqs[0]?.unlimitedAttempts || false).toString()}
          options={[
            { value: 'false', label: 'No' },
            { value: 'true', label: 'Yes' },
          ]}
          onChange={(e) => handleMcqChange('unlimitedAttempts', e.target.value === 'true')}
        />
      </div>

      {!isUnlimited && (
        <>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Retry Attempts</label>
            <input
              type="number"
              value={lectureData.mcqs[0]?.retryAttempts || ''}
              onChange={(e) => handleMcqChange('retryAttempts', e.target.value)}
              className="mt-1 p-1.5 block w-full border border-gray-300 rounded-md shadow-sm"
            />
                      {errors.retryAttempts && (
          <p className="mt-1 text-sm text-red-600">{errors.retryAttempts}</p> // Display error message
        )}

          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Minimum Question Attempts</label>
            <input
              type="number"
              value={lectureData.mcqs[0]?.minimumQuestionsAttempts || ''}
              onChange={(e) => handleMcqChange('minimumQuestionsAttempts', e.target.value)}
              className="mt-1 p-1.5 block w-full border border-gray-300 rounded-md shadow-sm"
            />
                                  {errors.minimumQuestionsAttempts && (
          <p className="mt-1 text-sm text-red-600">{errors.minimumQuestionsAttempts}</p> // Display error message
        )}

          </div>
        </>
      )}
    </div>
  </div>
)}

  {/* Submit button */}
  <div className="flex justify-end space-x-2 mt-4">
    <button
      type="submit"
      className="bg-[#027253] text-white py-2 px-4 rounded-md hover:bg-[#025a43] transition duration-300"
    >
      {isEditing ? 'Update' : 'Submit'}
    </button>
    <button
      type="button"
      onClick={() => setShowForm(false)}
      className="  py-2 px-4  bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 transition duration-300"
    >
      Back
    </button>
  </div>
</form>
        )}
      </div>
    </div>
  );
};

export default Step3;
