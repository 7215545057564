import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCourse } from '../../context/coursecontext';
import { Table, Space, Button, Popconfirm, message } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { post } from '../../services/fetch';
import Label from '../ui/Label';
import JoditEditor from 'jodit-react';
import CustomDropdown from '../ui/SingleSelect';
import {Clock,Calendar,Edit,Eye} from 'lucide-react'
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import './chapterstyle.css';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';

const Step2 = () => {

  const { courseId } = useCourse(); 
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    // Check if the '_GACOOKIE' token exists
    const token = Cookies.get('_GACOOKIE_ADMIN');
    if (!token) {
      // If token does not exist, redirect to the homepage
      navigate('/');
    }
  }, [navigate]);
  const searchParams = new URLSearchParams(location.search);
  const chapter_id = searchParams.get('chapter_id');
  const course_id = searchParams.get('course_id') || courseId;

  const [chapters, setChapters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [certificatePreview, setCertificatePreview] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const { control, register, handleSubmit, setValue, reset, formState: { errors } } = useForm({
    defaultValues: {
      chapter_title: '',
      short_desc: '',
      detailed_desc: '',
      duration: '',
      status: '',
      start_date: '',
      end_date: '',
    }
  });
  const handleBack = () => {
    navigate(`/add-course?id=${course_id}`);
  };
  useEffect(() => {
    // Set the document title based on the form's mode (add or edit)
    if (editMode) {
      document.title = "Edit Chapter | Medtalks Academy Admin";
    } else {
      document.title = "Add Chapter | Medtalks Academy Admin";
    }
  }, [editMode]);
  
  useEffect(() => {
    if (!editMode) {
      reset({
        chapter_title: '',
        short_desc: '',
        detailed_desc: '',
        duration: '',
        status: '',
        start_date: '',
        end_date: '',
      });
    }
  }, [editMode, reset]);
  
  const prefillFormData = (chapter) => {
    setValue('chapter_title', chapter.chapter_title || '');
    setValue('short_desc', chapter.short_desc || '');
    setValue('detailed_desc', chapter.detailed_desc || '');
    setValue('duration', chapter.duration || '');
    // Set only the value for status, not the whole object
    const statusValue = chapterStatus.find(item => item.value === chapter.status)?.value;
    setValue('status', statusValue || '');
    setValue('start_date', chapter.start_date || '');
    setValue('end_date', chapter.end_date || '');
  };
  
  const joditConfig = {
    height: 300,
    uploader: { insertImageAsBase64URI: true },
    buttons: 'bold,italic,underline,|,ul,ol,|,outdent,indent,|,link,|,undo,redo',
    placeholder:'',
  };

  useEffect(() => {
    if (course_id) {
      fetchChapters(course_id);
    }
  }, [course_id]);

  useEffect(() => {
    if (chapter_id) {
      handleEdit(chapter_id);
    }
  }, [chapter_id]);

  const chapterStatus = [
    { value: 'live', label: 'Live' },
    { value: 'schedule', label: 'Schedule' },
    { value: 'draft', label: 'Draft' },
    { value: 'archive', label: 'Archive' }
  ];


  const fetchChapters = async (courseId) => {
    setLoading(true);
    setError(null);
    try {
      const res = await post('get-chapter', { course_id: courseId }, true, true);
      if (res.code == 200) {
        setChapters(res.data);
     
      } else {
        setShowForm(false); // Ensure form is not shown when there are no chapters

      }
    } catch (error) {
      console.error('Error fetching chapters:', error);
      setError('An error occurred while fetching chapters.');
    } finally {
      setLoading(false);
    }
  };


  const fetchChapterDetails = async (chapterId, courseId) => {
    setLoading(true);
    try {
      const response = await post('get-chapter', { chapter_id: chapterId, course_id: courseId }, true);
      if (response && response.code == 200) {
        const chapter = response.data[0];
        
        // Set the values for form fields
        prefillFormData(chapter);

        // Set the certificate preview if it exists
        setCertificatePreview(chapter.certificate || null);
        
        // Show the form and set edit mode
        setShowForm(true);
        setEditMode(true);
      } else {
        setError('Failed to fetch chapter details.');
      }
    } catch (error) {
      console.error('Error fetching chapter details:', error);
      setError('An error occurred while fetching chapter details.');
    } finally {
      setLoading(false);
    }
  };

  const handleAddChapter = () => {
    setShowForm(true);
    setEditMode(false);
    setCertificatePreview(null);
    
    reset({
      chapter_title: '',
      short_desc: '',
      detailed_desc: '',
      duration: '',
      status: '',
      start_date: '',
      end_date: '',
    });
  };
  
  const handleEdit = (chapterId) => {
    setShowForm(true);
    setEditMode(true);
    fetchChapterDetails(chapterId, course_id);
    navigate(`/add-chapter?course_id=${courseId}&chapter_id=${chapterId}`);

  };
  const [fileList, setFileList] = useState([]);

  const validateFile = (file) => {
    return new Promise((resolve, reject) => {
      // Check file size (1 MB = 1048576 bytes)
      if (file.size > 1048576) {
        message.error('File size must be less than 1 MB');
        reject();
        return;
      }

      // Check image dimensions
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        URL.revokeObjectURL(img.src);
        if (img.width !== 1600 || img.height !== 1165) {
          message.error('Image dimensions must be 1600x1165 pixels');
          reject();
        } else {
          resolve();
        }
      };
      img.onerror = () => {
        URL.revokeObjectURL(img.src);
        message.error('Invalid image file');
        reject();
      };
    });
  };

  const handleFileInputChange = async ({ fileList }) => {
    const file = fileList[0]?.originFileObj;
    if (file) {
      try {
        await validateFile(file);
        setFileList(fileList);
        // Convert file to base64 to save in form data
        const reader = new FileReader();
        reader.onload = () => {
          const base64String = reader.result;
          setValue('certificate', base64String);
          setCertificatePreview(base64String);
        };
        reader.readAsDataURL(file);
      } catch (error) {
        setFileList([]);
        setValue('certificate', null);
        setCertificatePreview(null);
      }
    } else {
      setFileList([]);
      setValue('certificate', null);
      setCertificatePreview(null);
    }
  };
  const columns = [
    {
      title: 'Title',
      dataIndex: 'chapter_title',
      key: 'chapter_title',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        const color = status === 'live' ? 'green' : 'black';
        return <span style={{ color, fontWeight: 'bold', textTransform: 'capitalize' }}>{status}</span>;

      },
    },
    {
      title: 'Short Description',
      dataIndex: 'short_desc',
      key: 'short_desc',
      render: (text) => (
        <span
          dangerouslySetInnerHTML={{
            __html: text.length > 100 ? `${text.substring(0, 100)}...` : text,
          }}
        />
      ),

    },
    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'start_date',
      render: (date) => <span style={{ whiteSpace: 'nowrap' }}>{dayjs(date).format('YYYY-MM-DD')}</span>,
    },
    {
      title: 'End Date',
      dataIndex: 'end_date',
      key: 'end_date',
      render: (date) => <span style={{ whiteSpace: 'nowrap' }}>{dayjs(date).format('YYYY-MM-DD')}</span>,
    },
    
   
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button 
            icon={<EditOutlined />} 
            onClick={() => handleEdit(record.id)}
            className="text-blue-500 hover:text-blue-700"
          />
         
         <Popconfirm
          title="Are you sure you want to delete this chapter?"
          onConfirm={() => handleDeleteConfirmation(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <Button 
            icon={<DeleteOutlined />} 
            className="text-red-500 hover:text-red-700"
          />
        </Popconfirm>
          <Button 
            icon={<EyeOutlined />} 
            onClick={() => handleViewLectures(record.id)}
            className="text-green-500 hover:text-green-700"
          />
        </Space>
      ),
    },
  ];




  const uploadProps = {
    fileList,
    onChange: handleFileInputChange,
    beforeUpload: () => false, // Prevent automatic upload
    accept: 'image/*', // Accept only image files
  };
const onSubmitForm = async (data) => {
  if (!course_id) {
    setError('Course ID is missing. Please make sure the course is selected.');
    return;
  }

  try {
    const endpoint = editMode ? 'update-chapter' : 'add-chapter';
    const payload = {
      ...data,
      course_id,
      ...(editMode && { id: chapter_id }), // Add the chapter_id as 'id' field only when editing
    };

    const response = await post(endpoint, payload, true);
    if (response && response.code === 200) {
      message.success(`Chapter ${editMode ? 'updated' : 'added'} successfully!`);
      // Fetch the updated chapter list after updating the chapter
      await fetchChapters(course_id);
      // Reset the form state and redirect back to the chapter listing page
      setShowForm(false);
      setEditMode(false);
      navigate(`?course_id=${course_id}`);
    } else {
      setError('An error occurred while submitting the form.');
    }
  } catch (e) {
    console.error('Error posting to API:', e);
    setError('An error occurred while submitting the form.');
  }

};

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-green-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
        <strong className="font-bold">Error!</strong>
        <span className="block sm:inline"> {error}</span>
      </div>
    );
  }
  const handleViewLectures = (chapterId) => {
    navigate(`/add-lecture?chapter_id=${chapterId}&step=3`);
  };

  const handleDelete = async (chapterId) => {
    setLoading(true);
    try {
      const payload = {
        id: chapterId,
        type: 'chapter'
      };
  
      const response = await post('delete-content', payload, true);
      if (response && response.code === 200) {
        message.success('Chapter deleted successfully!');
  
        // Update the state to remove the deleted chapter immediately
        setChapters((prevChapters) => prevChapters.filter((chapter) => chapter.id !== chapterId));
  
        // Optionally, you can still refresh the list of chapters to ensure data consistency
        // await fetchChapters(course_id);
      } else {
        setError('Failed to delete chapter.');
      }
    } catch (error) {
      console.error('Error deleting chapter:', error);
      setError('An error occurred while deleting the chapter.');
    } finally {
      setLoading(false);
    }
  };
  
  const handleDeleteConfirmation = (chapterId) => {
    handleDelete(chapterId);
  };
  
  
  
  return (
<div className="min-h-screen bg-white py-3 overflow-auto">
<div className="container mx-auto px-4 sm:px-6 lg:px-8">
        {/* Back Button */}
        {/* <button 
          onClick={handleBack} 
          className="flex items-center space-x-2 text-gray-600 hover:text-gray-800 mb-4"
        >
          <ArrowLeftOutlined />
          <span>Back to Course</span>
        </button> */}

  <div className="container mx-auto px-4 sm:px-6 lg:px-8">
    <h1 className="text-lg sm:text-xl font-semibold mb-8 text-black">
      {showForm && (editMode ? 'Edit Chapter' : 'Add Chapter')}
    </h1>

    {!showForm && (
      <div className="mb-6">
        {chapters.length === 0 ? (
          <div className="text-center py-10">
            <p className="text-lg sm:text-xl text-gray-600">
              No chapters available for this course yet.
            </p>
            <button
              onClick={handleAddChapter}
              className="mt-6 bg-white  text-[#247F4D] font-bold py-3 px-4 sm:px-6 rounded-2xl transition-colors duration-300 shadow-sm border-2 border-[#247F4D] hover:text-white hover:bg-[#247F4D] "
            >
              Add Your First Chapter
            </button>
          </div>
        ) : (
          <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
            <h2 className="text-lg sm:text-xl font-semibold text-gray-700">
              📚 Chapters Gallery
            </h2>
            <button
              onClick={handleAddChapter}
              className="mt-4 sm:mt-0 bg-white  text-[#247F4D] border-[1px]  border-[#247F4D] font-bold py-3 px-4 sm:px-6 rounded-2xl transition-colors duration-300 shadow-sm hover:text-white hover:bg-[#247F4D]"
            >
              Add New Chapter
            </button>
          </div>
        )}
      </div>
    )}

    {showForm ? (
      <form onSubmit={handleSubmit(onSubmitForm)} className="space-y-6">
        <div>
          <label htmlFor="chapter_title" className="block text-sm font-medium text-gray-700 mb-1">
            Chapter Title
          </label>
          <Controller
            name="chapter_title"
            control={control}
            render={({ field }) => (
              <input
                {...register('chapter_title', { required: 'Chapter title is required' })}
                type="text"
                className="w-full p-3 border rounded-md"
                required
              />
            )}
          />
        </div>

        <div>
          <label htmlFor="short_desc" className="block text-sm font-medium text-gray-700 mb-1">
            Short Description
          </label>
          <Controller
            name="short_desc"
            control={control}
            render={({ field }) => (
              <JoditEditor {...field} config={joditConfig} />
            )}
          />
        </div>

        <div>
          <label htmlFor="detailed_desc" className="block text-sm font-medium text-gray-700 mb-1">
            Detailed Description
          </label>
          <Controller
            name="detailed_desc"
            control={control}
            render={({ field }) => (
              <JoditEditor {...field} config={joditConfig} />
            )}
          />
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <label htmlFor="duration" className="block text-sm font-medium text-gray-700 mb-1 w-full">
              Duration (HH:MM:SS)
            </label>
            <Controller
            name="duration"
            control={control}
            rules={{
              required: 'Duration is required',
              pattern: {
                value: /^([0-1][0-9]|2[0-4]):([0-5][0-9]|60):([0-5][0-9]|60)$/,
                message: 'Invalid duration format. Hours should be 00-24, minutes and seconds should be 00-60',
              },
            }}
            render={({ field: { onChange, value, ...field } }) => (
              <input
                {...field}
                value={value}
                type="text"
                placeholder="00:00:00"
                maxLength={8}
                className="w-full p-3 border rounded-md focus:ring-2 focus:ring-green-500"
                onChange={(e) => {
                  let val = e.target.value.replace(/\D/g, '');
                  if (val.length >= 2) val = val.slice(0, 2) + ':' + val.slice(2);
                  if (val.length >= 5) val = val.slice(0, 5) + ':' + val.slice(5, 7);
                  onChange(val);
                }}
              />
            )}
            />
            {errors.duration && (
            <p className="mt-1 text-sm text-red-600">
              {errors.duration.message}
            </p>
          )}
          </div>

          <div>
            <Controller
              name="status"
              control={control}
              rules={{ required: 'Please select a status' }}
              render={({ field }) => (
                <>
                  <Label htmlFor="status">Status</Label>
                  <CustomDropdown
                    {...field}
                    options={chapterStatus}
                    className="w-full border border-gray-200 rounded-md focus:border-green-400 focus-visible:outline-none "
                    placeholder="Select status..."
                  />
                  {errors.status && <p className="text-red-500 text-sm mt-1">{errors.status.message}</p>}
                </>
              )}
            />
          </div>
        </div>

        <div>
          <label htmlFor="certificate" className="block text-sm font-medium text-gray-700 mb-1">
            Certificate (1600x1165 pixels, max 1 MB)
          </label>
          <Upload {...uploadProps} listType="picture" maxCount={1}>
            <Button icon={<UploadOutlined />}>Upload Certificate</Button>
          </Upload>
          {certificatePreview && (
            <div className="mt-4">
              <img src={certificatePreview} alt="Certificate preview" className="max-w-full h-auto rounded-md shadow-md my-3" />
            </div>
          )}
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <label htmlFor="start_date" className="block text-sm font-medium text-gray-700 mb-1">
              Start Date
            </label>
            <Controller
              name="start_date"
              control={control}
              render={({ field }) => (
                <input {...field} type="date" className="w-full p-3 border rounded-md" />
              )}
            />
          </div>
          <div>
            <label htmlFor="end_date" className="block text-sm font-medium text-gray-700 mb-1">
              End Date
            </label>
            <Controller
              name="end_date"
              control={control}
              render={({ field }) => (
                <input {...field} type="date" className="w-full p-3 border rounded-md" />
              )}
            />
          </div>
        </div>

        <div className="flex space-x-2 justify-end">
          <button
            type="submit"
            className="px-4 py-2 bg-[#027253] text-white rounded-md hover:bg-[#025a42] focus:outline-none"
          >
            {editMode ? 'Update' : 'Submit'}
          </button>
          <button
            type="button"
            onClick={() => {
              setShowForm(false);
              setEditMode(false);
              navigate(`?course_id=${course_id}`);
            }}
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none"
          >
            Cancel
          </button>
        </div>
      </form>
    ) : (
      <div>
        {chapters.length > 0 && (
          <Table
            columns={columns}
            dataSource={chapters}
            rowKey="id"
            pagination={false}
            className="bg-white rounded-lg shadow-lg"
          />
        )}
      </div>
    )}
  </div>
</div>
</div>
  );
}           
export default Step2;
